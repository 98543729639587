<template>
  <div class="home">
    <!-- <Home msg="Welcome to Your Vue.js App" /> -->

    <Home :menu="menu" />
  </div>
</template>

<script>
// @ is an alias to /src
import Home from "@/components/Home.vue"

export default {
  name: "HomeView",
  components: {
    Home,
  },
  props: ["menu"],
}
</script>
