<template>
  <v-app id="app">
    <v-app-bar app class="header">
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          class="logosm"
          :class="isMobile ? '' : 'pd-l'"
          contain
          :src="require('./assets/logo.png')"
        />
      </div>

      <v-spacer></v-spacer>
      <div v-if="isMobile">
        <v-icon
          id="menu-icon"
          class="menu-icon mt-2 mr-2"
          @click.stop.prevent="openMenu()"
        >
          mdi-menu
        </v-icon>
      </div>
      <div v-else class="pd-r">
        <router-link to="/">
          <span id="menu-home" class="menu mr-5">
            {{ $t("menu.home") }}
          </span>
        </router-link>

        <router-link to="/about">
          <span id="menu-about" class="menu mr-5">
            {{ $t("menu.about") }}
          </span>
        </router-link>

        <router-link to="/menu">
          <span id="menu-menu" class="menu mr-5">
            {{ $t("menu.menu") }}
          </span>
        </router-link>

        <router-link to="/service">
          <span id="menu-service" class="menu mr-5">
            {{ $t("menu.service") }}
          </span>
        </router-link>

        <router-link to="/news">
          <span id="menu-news" class="menu mr-5">
            {{ $t("menu.news") }}
          </span>
        </router-link>

        <router-link to="/investor">
          <span id="menu-investor" class="menu mr-5">
            {{ $t("menu.investor") }}
          </span>
        </router-link>

        <router-link to="/contact">
          <span id="menu-contact" class="menu mr-5">
            {{ $t("menu.contact") }}
          </span>
        </router-link>

        <span id="menu-en" class="lang mr-1" @click.stop.prevent="lang = 'en'">
          EN
        </span>
        <span class="menu mr-1">|</span>
        <span id="menu-th" class="lang mr-1" @click.stop.prevent="lang = 'th'">
          ไทย
        </span>
      </div>
    </v-app-bar>

    <v-main>
      <div class="menu-div" id="menu-div">
        <div class="menu-text">
          <h1
            class="menu-mobile"
            id="menu-home-mobile"
            @click.stop.prevent="gotoPage('/')"
          >
            {{ $t("menu.home") }}
          </h1>

          <h1
            class="menu-mobile"
            id="menu-about-mobile"
            @click.stop.prevent="gotoPage('/about')"
          >
            {{ $t("menu.about") }}
          </h1>

          <h1
            class="menu-mobile"
            id="menu-menu-mobile"
            @click.stop.prevent="gotoPage('/menu')"
          >
            {{ $t("menu.menu") }}
          </h1>

          <h1
            class="menu-mobile"
            id="menu-service-mobile"
            @click.stop.prevent="gotoPage('/service')"
          >
            {{ $t("menu.service") }}
          </h1>

          <h1
            class="menu-mobile"
            id="menu-news-mobile"
            @click.stop.prevent="gotoPage('/news')"
          >
            {{ $t("menu.news") }}
          </h1>

          <h1
            class="menu-mobile"
            id="menu-investor-mobile"
            @click.stop.prevent="gotoPage('investor/')"
          >
            {{ $t("menu.investor") }}
          </h1>

          <h1
            class="menu-mobile"
            id="menu-contact-mobile"
            @click.stop.prevent="gotoPage('/contact')"
          >
            {{ $t("menu.contact") }}
          </h1>
        </div>

        <div class="mobile-menu-footer">
          <center>
            <span>
              <br /><br />
              ©Copyright 2022 eggalldays Co., Ltd.
            </span>
          </center>
        </div>

        <v-icon class="menu-close mt-2 mr-2" @click.stop.prevent="closeMenu()">
          mdi-close
        </v-icon>
      </div>

      <!-- <Home :menu="menu" /> -->
      <router-view :menu="menu" />

      <section ref="footer" class="footer">
        <div>
          <v-row justify="center">
            <v-col cols="1" v-if="!isMobile"> </v-col>
            <v-col cols="12" sm="12" md="2" lg="2" xl="2">
              <v-img
                alt="Logo"
                class="pd-l logomd"
                contain
                :src="require('./assets/logo.png')"
              />
            </v-col>

            <v-col cols="12" sm="12" md="8" lg="8" xl="8">
              <table>
                <tr>
                  <td width="30%" v-if="!isMobile">
                    <h4>Branches</h4>
                    <span> <v-icon> mdi-store </v-icon> Ekamai</span>
                    <br />
                    <span> <v-icon> mdi-store </v-icon> Rajchapruek</span>
                    <br />
                    <span> <v-icon> mdi-store </v-icon> Phuket</span>
                    <br />
                  </td>
                  <td width="40%" v-if="!isMobile">
                    <h4>Contact</h4>
                    <span> <v-icon> mdi-cellphone </v-icon> 084 289 1451</span>
                    <br />
                    <span>
                      <v-icon> mdi-email </v-icon> eggalldays@gmail.com</span
                    >
                    <br />
                    <span>
                      <v-icon> mdi-map-marker </v-icon> Bangkok Metropolis,
                      Thailand
                      <br />
                      Phra Khanong, Bangkok
                    </span>
                    <br />
                  </td>
                  <td
                    :width="isMobile ? '30%' : '100%'"
                    :style="isMobile ? 'text-align: center' : ''"
                  >
                    <h4>Follow Us</h4>
                    <span>
                      <a
                        href="https://www.facebook.com/eggalldayofficial"
                        target="_blank"
                      >
                        <v-img
                          alt="icon"
                          class="icon"
                          contain
                          :src="require('@/assets/fb-icon.svg')"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/eggallday/"
                        target="_blank"
                      >
                        <v-img
                          alt="icon"
                          class="icon"
                          contain
                          :src="require('@/assets/insta-icon.svg')"
                        />
                      </a>

                      <a
                        href="https://page.line.me/538nwaez?openQrModal=true"
                        target="_blank"
                      >
                        <v-img
                          alt="icon"
                          class="icon"
                          contain
                          :src="require('@/assets/line-icon.svg')"
                        />
                      </a>

                      <a href="https://twitter.com/EggAllDay1" target="_blank">
                        <v-img
                          alt="icon"
                          class="icon"
                          contain
                          :src="require('@/assets/twitter-icon.svg')"
                        />
                      </a>
                    </span>
                    <br />
                    <br />
                    <span> © Copyright 2022 eggalldays Co., Ltd. </span>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col cols="1" v-if="!isMobile"> </v-col>
          </v-row>
        </div>
      </section>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    menu: "",
    lang: "",
    isMobile: false,
    showMenu: false,
  }),
  watch: {
    menu() {
      if (this.isMobile) {
        if (this.$route.path == "/") {
          let arr = document.getElementsByClassName("menu-mobile")
          for (let i = 0; i < arr.length; i++) {
            arr[i].classList.remove("active")
          }
          document
            .getElementById("menu-" + this.menu + "-mobile")
            .classList.add("active")
          this.closeMenu()
        } else {
          this.$router.push({ name: this.menu })
          this.closeMenu()
        }
      } else {
        if (this.$route.path == "/") {
          let arr = document.getElementsByClassName("menu")
          for (let i = 0; i < arr.length; i++) {
            arr[i].classList.remove("active")
          }
          document.getElementById("menu-" + this.menu).classList.add("active")
        } else {
          this.$router.push({ name: this.menu })
        }
      }
    },
    lang() {
      if (this.lang == "th") {
        document.getElementById("menu-en").classList.remove("active")
        document.getElementById("menu-th").classList.add("active")
        this.$i18n.locale = "th"
      } else {
        document.getElementById("menu-en").classList.add("active")
        document.getElementById("menu-th").classList.remove("active")
        this.$i18n.locale = "en"
      }
    },
  },
  beforeCreate: function () {
    document.body.className = "hold-transition login-page"
  },
  created() {
    window.addEventListener("resize", this.detectWindowSize)
  },
  destroyed() {
    window.removeEventListener("resize", this.detectWindowSize)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
    this.isMobile = window.innerWidth < 768 ? true : false
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text !== null) {
        return text.substring(0, length) + suffix
      }
    },
  },
  methods: {
    openMenu() {
      document.getElementById("app").style.overflowY = "hidden"
      document.getElementById("menu-icon").style.display = "none"
      document.getElementById("menu-div").style.display = "block"
    },
    closeMenu() {
      document.getElementById("app").style.overflowY = "auto"
      document.getElementById("menu-icon").style.display = "block"
      document.getElementById("menu-div").style.display = "none"
    },
    gotoPage(page) {
      this.$router.push(page)
      this.closeMenu()
    },
  },
}
</script>

<style lang="scss">
@import "../public/fonts/styles.scss";

html,
body,
.v-application {
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Mali", sans-serif !important;
}
.col {
  padding: 0 !important;
}
.container,
section {
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: hidden;
}
// HEADER
.header {
  height: 100px !important;
  padding: 10px;
  background: #fff !important;
  box-shadow: unset !important;
  position: fixed;
  top: 0;
}
.pd-l {
  padding-left: 100px;
}
.pd-r {
  padding-right: 100px;
}
.menu {
  font-size: 18px;
}
.active {
  font-weight: 900;
  color: #f4cb1e;
}
.menu-icon {
  font-size: 48px !important;
  color: #f4cb1e;
}
.menu-close {
  font-size: 48px !important;
  color: #333333 !important;
  position: absolute !important;
  top: 20px;
  right: 20px;
}
.menu-div {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  color: #333333;
  background-color: #fff;
  z-index: 100;
}
.menu-text {
  transform: translate(30%, 60%);
}
.mobile-menu-footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 80px;
  background: url("./assets/mobile-menu-footer.png") no-repeat;
  background-size: cover;

  span {
    margin-top: 50px;
  }
}

// FOOTER
.footer {
  padding: 20px 0;
  min-height: 0 !important;
}

// HOME
.v-input .v-label {
  font-size: 1rem;
  color: #333333 !important;
}
.v-main {
  padding: 0 !important;
}
h1 {
  font-size: 60px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 14px;
}

.heading {
  height: 100px;
  background-color: #c9dff4;
}
.home-div {
  transform: translateY(15vh);
}
.about-div {
  transform: translateY(20vh);
}
.ourmenu-div {
  transform: translateY(25vh);
}
.service-div {
  transform: translateY(25vh);
}
.news-div {
  transform: translateY(15vh);
  padding: 0 100px;
  height: 80vh;
}
// .news-div {
//   transform: translateY(25vh);
//   padding: 0 100px;
//   height: 80vh;
//   color: #fff;
// }
.bg-gradirent {
  background: radial-gradient(#ffffff, #c0d9f2);
}
.body-text {
  padding: 50px 100px;
}
.body-heading {
  margin: 0 auto;
  text-align: center;
  margin: 20px 0;
  padding-top: 20px;
}
.no-padding {
  padding: 0;
  max-width: 100vw;
}
.icon {
  display: inline-block !important;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}
.logosm {
  height: 75px;
  margin-top: 10px;
}
.logomd {
  height: 100px;
  margin-top: 20px;
}
.logo {
  height: 200px;
}
.banner {
  height: 50vh;
}
.subheading {
  padding: 0 100px 0 50px;
}
.bg-1 {
  background: url("./assets/menubg.png") no-repeat;
  background-size: cover;
}
.bg-2 {
  background: url("./assets/newsbg.png") no-repeat;
  background-size: cover;
}
.bg-3 {
  background: url("./assets/bg3.png") no-repeat;
  background-size: cover;
  height: 300px;
  margin: 100px 0 50px 0;
}
.VueCarousel {
  width: 85vw;
  margin: 50px auto;
}
.VueCarousel-inner {
  height: 300px !important;
}
.VueCarousel-pagination {
  margin-top: -20px;
  display: block !important;
}
.VueCarousel-navigation {
  display: block !important;
  button {
    font-size: 36px;
    opacity: 0.5;
  }
}
.slide {
  background-size: cover;
  position: relative;
  margin: 0 20px;
  width: 250px;
  height: 250px;
  border: 15px solid #ffde55;
  border-radius: 50%;
  // box-shadow: -9px 11px 15px -2px rgba(0, 0, 0, 0.75);
  // -webkit-box-shadow: -9px 11px 15px -2px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: -9px 11px 15px -2px rgba(0, 0, 0, 0.75);
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  // top: -50px;
  bottom: 0;
  // left: -50px;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #f4cb1e;
}

.slide:hover .overlay {
  opacity: 0.75;
}

.text {
  color: white;
  width: 100%;
  font-size: 60px;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  i {
    padding: 0 10px;
    font-size: 40px !important;
  }
}

.mybtn {
  background-color: #f4cb1e !important;
  span {
    color: #333333 !important;
    font-weight: 700;
  }
}
.mybtn-white {
  background-color: #fff !important;
  span {
    color: #333333 !important;
    font-weight: 700;
  }
}
.btn-career {
  margin-top: 50px;
  span {
    color: #fff;
    font-size: 24px;
    padding: 20px;
  }
}
.career-bg {
  position: absolute;
  bottom: 0;
  right: 50px;
}
.career-txt {
  width: 50%;
}
.v-text-field__slot {
  input,
  label {
    color: #fff !important;
  }
}
.v-select__slot {
  input,
  label,
  i,
  .v-select__selection,
  .v-select__selection--comma {
    // color: #fff !important;
    font-size: 1.2em;
  }
}
.btn-contact {
  margin-top: 50px;
  span {
    color: #fff;
    padding: 20px;
  }
}
a,
a:visited {
  text-decoration: none;
  color: #333333 !important;
}
// BLOG
.post {
  a,
  a:visited {
    text-decoration: none;
    color: #333333 !important;
  }
}
.post-image {
  max-width: 100%;
  border-radius: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 1025px) {
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 14px;
  }
  .pd-l {
    padding-left: 20px;
  }
  .home-div {
    transform: translateY(20vh);
  }
  .about-div {
    transform: translateY(15vh);
    .subheading {
      padding: 0 10px;
    }
  }
  .work-div {
    transform: translateY(20vh);
    height: auto;
  }
  .career-div {
    transform: translateY(5vh);
    padding: 0 100px;
    height: 80vh;
  }
  .contact-div {
    transform: translateY(10vh);
    padding: 0 100px;
    // height: 80vh;
    color: #fff;
  }
  .hero-banner1,
  .hero-banner2 {
    width: 80vw;
    transform: translateY(5vh);
    margin: 0 auto;
  }
  .display-2 {
    margin-left: 50px !important;
  }
  .VueCarousel-inner {
    height: 600px !important;
  }
  .slide {
    // width: 90vw;
    // height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  section {
    min-height: 65vh !important;
  }
  .menu-text {
    transform: translate(15%, 40%);
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 14px;
  }
  .pd-l {
    padding-left: 20px;
  }
  .bg-3 {
    background: url("./assets/bg3.png") no-repeat;
    background-size: cover;
    min-height: 50px !important;
    margin: 0;
    margin-top: 100px;
  }
  .logosm {
    .v-image__image.v-image__image--contain {
      background-position: unset !important;
    }
  }
  .home-div {
    transform: translateY(15vh);
  }
  .about-div {
    transform: translateY(5vh);
    padding-bottom: 100px;
    .subheading {
      padding: 0 10px;
    }
  }
  .ourmenu-div {
    transform: translateY(15vh);
  }
  .VueCarousel-wrapper {
    height: 350px;
  }
  .VueCarousel-navigation-prev {
    margin-left: 15px;
  }
  .VueCarousel-navigation-next {
    margin-right: 15px;
  }
  .work-div {
    transform: translateY(20vh);
    height: auto;
  }
  .service-div {
    transform: translateY(5vh);
    // padding: 0 50px;
    // height: 80vh;
  }
  .news-div {
    transform: translateY(5vh);
    padding: 0 50px;
    height: auto;
    // color: #fff;
    padding-bottom: 100px;
  }

  // .VueCarousel-pagination {
  //   margin-top: -200px;
  // }
  .hero-banner1 {
    width: 70vw;
    // transform: translateY(5vh);
    margin: 0 auto;
  }
  .hero-banner2 {
    width: 90vw;
    transform: translateY(5vh);
    margin: 0 auto;
  }
  .body-text {
    padding: 50px;
  }
  .display-2 {
    margin-left: 50px !important;
  }
  .career-txt {
    width: 100%;
  }
  .VueCarousel-inner {
    height: 600px !important;
  }
  .slide {
    // width: 90vw;
    height: 250px;
  }
}
</style>
