import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("../views/Menu.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/Service.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/News.vue"),
  },
  {
    path: "/investor",
    name: "investor",
    component: () => import("../views/Investor.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  // mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
