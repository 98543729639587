<template>
  <div>
    <section ref="home" class="no-padding">
      <div class="home-div">
        <v-row>
          <v-col v-if="!isMobile" cols="12" class="mb-4">
            <v-img
              alt="Banner"
              contain
              :src="require('../assets/Banner.png')"
            />
          </v-col>

          <v-col
            v-if="isMobile"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
            class="mb-4"
          >
            <v-img
              alt="Banner"
              contain
              :src="require('../assets/mobilebg.png')"
            />
          </v-col>
        </v-row>
      </div>
    </section>

    <section ref="about" class="no-padding">
      <div class="about-div">
        <v-row class="px-5">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="!isMobile">
            <v-img
              alt="Banner"
              class="pd-l banner"
              contain
              :src="require('../assets/aboutus.png')"
            />
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="subheading">
            <v-container>
              <h2>ABOUT US</h2>
              <p style="margin-top: 50px">
                Vestibulum augue tortor, consequat eu diam a, varius pulvinar
                ipsum. Vivamus posuere, mauris eu interdum molestie, dui ligula
                interdum tortor, at pretium ligula quam ut est. Ut mollis nisi
                quis magna hendrerit vulputate. Fusce sagittis rhoncus tortor
                Vestibulum augue tortor, consequat eu diam a, varius pulvinar
                ipsum. Vivamus posuere, mauris eu interdum molestie, dui ligula
                interdum tortor, at pretium ligula quam ut est. Ut mollis nisi
                quis magna hendrerit vulputate. Fusce sagittis rhoncus tortor
              </p>
              <router-link to="/about">
                <v-btn rounded height="50" class="mybtn btn-career">
                  View More
                </v-btn>
              </router-link>
            </v-container>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="isMobile">
            <v-img
              alt="Banner"
              class="pd-l banner"
              contain
              :src="require('../assets/aboutus.png')"
            />
          </v-col>
        </v-row>
      </div>
    </section>

    <section ref="menu" class="bg-1 no-padding">
      <div class="ourmenu-div">
        <v-row justify="center">
          <h2>OUR MENU</h2>
        </v-row>
        <v-row justify="center">
          <center>
            <carousel
              :per-page="isMobile ? 1 : 3"
              :mouse-drag="true"
              :paginationEnabled="true"
              :navigationEnabled="true"
              loop
            >
              <slide v-for="item in menu_items" :key="item.id">
                <div
                  class="slide"
                  :style="'background: url(' + item.img + ') no-repeat center'"
                >
                  &nbsp;
                  <div class="overlay">
                    <div class="text">
                      <v-icon color="white"> mdi-magnify </v-icon>
                      <v-icon color="white"> mdi-share </v-icon>
                      <h4>{{ item.txt }}</h4>
                    </div>
                  </div>
                </div>
                <br />
                <span>{{ item.txt }}</span>
              </slide>
            </carousel>
          </center>
        </v-row>

        <v-row justify="center">
          <router-link to="/menu">
            <v-btn rounded height="50" class="mybtn-white btn-career">
              View More
            </v-btn>
          </router-link>
        </v-row>
      </div>
    </section>

    <section ref="service" class="no-padding">
      <div class="service-div">
        <v-row class="px-5">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="subheading">
            <v-container>
              <h2>OUR SERVICE</h2>
              <p style="margin-top: 50px; margin-right: 50px">
                Vestibulum augue tortor, consequat eu diam a, varius pulvinar
                ipsum. Vivamus posuere, mauris eu interdum molestie, dui ligula
                interdum tortor, at pretium ligula quam ut est. Ut mollis nisi
                quis magna hendrerit vulputate. Fusce sagittis rhoncus tortor
                Vestibulum augue tortor, consequat eu diam a, varius pulvinar
                ipsum. Vivamus posuere, mauris eu interdum molestie, dui ligula
                interdum tortor, at pretium ligula quam ut est. Ut mollis nisi
                quis magna hendrerit vulputate. Fusce sagittis rhoncus tortor
              </p>

              <router-link to="/service">
                <v-btn rounded height="50" class="mybtn btn-career">
                  View More
                </v-btn>
              </router-link>
            </v-container>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-img
              alt="Banner"
              class="pd-l banner"
              contain
              :src="require('../assets/service.png')"
            />
          </v-col>
        </v-row>
      </div>
    </section>

    <section ref="news" class="bg-2 no-padding">
      <div class="news-div">
        <v-row class="mb-5" justify="center">
          <h2>OUR NEWS</h2>
        </v-row>
        <v-row class="mt-5" justify="center" v-if="news">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="post">
              <a :href="news[0].slug">
                <div>
                  <img
                    :src="news[0].feature_image"
                    alt="Image"
                    class="post-image"
                  />

                  <div class="mt-4">
                    <h4>{{ news[0].created_at.substring(0, 10) }}</h4>
                  </div>

                  <h3 class="post-title">{{ news[0].title }}</h3>

                  <p>{{ news[0].excerpt | truncate(200, "...") }}</p>

                  <div class="mt-4">
                    <h4>Read More</h4>
                  </div>
                </div>
              </a>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-row v-for="n in news.slice(1, 5)" :key="n.id">
              <div class="post my-5">
                <a :href="n.slug">
                  <v-container>
                    <v-row justify="center" align="center">
                      <v-col cols="3">
                        <img
                          :src="n.feature_image"
                          alt="Image"
                          class="post-image"
                        />
                      </v-col>
                      <v-col cols="9" class="pl-3">
                        <div>
                          <h5>{{ n.created_at.substring(0, 10) }}</h5>
                        </div>

                        <h3 class="post-title">{{ n.title }}</h3>

                        <p>{{ n.excerpt | truncate(50, "...") }}</p>

                        <div style="margin-top: -20px">
                          <h4>Read More</h4>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </a>
              </div>
            </v-row>
          </v-col>

          <router-link to="/news">
            <v-btn rounded height="50" class="mybtn-white btn-career">
              View More
            </v-btn>
          </router-link>
        </v-row>
      </div>
    </section>
  </div>
</template>

<script>
import { getPostbyTag } from "@/api/posts"

export default {
  name: "FirstPage",
  props: ["menu"],
  data: () => ({
    news: [],
    staff: [],
    menu_items: [
      { img: "./img/menu1.png", txt: "Mango Yogurt Smoothie" },
      {
        img: "./img/menu2.png",
        txt: "Black Truffles Sauce Ham Cheese Egg Toast",
      },
      { img: "./img/menu3.png", txt: "Blink Fuzzy Soda" },
      { img: "./img/menu4.png", txt: "GOLDEN FISH EGG TOAST" },
      {
        img: "./img/menu5.png",
        txt: "Egg All Day x YUZU House",
      },
      { img: "./img/menu6.png", txt: "EAD Thai Milk Tea" },
    ],
    items: ["สมัครงาน", "ติดต่อบริษัท", "เรื่องอื่นๆ"],
    isMobile: false,
  }),
  watch: {
    menu() {
      this.scrollMeTo(this.menu)
    },
  },
  created() {
    window.addEventListener("resize", this.detectWindowSize)
  },
  destroyed() {
    window.removeEventListener("resize", this.detectWindowSize)
  },
  mounted() {
    this.isMobile = window.innerWidth < 1024 ? true : false

    if (this.isMobile) {
      let arr = document.getElementsByClassName("menu-mobile")
      for (let i = 0; i < arr.length; i++) {
        arr[i].classList.remove("active")
      }
      document.getElementById("menu-home-mobile").classList.add("active")
    } else {
      let arr = document.getElementsByClassName("menu")
      for (let i = 0; i < arr.length; i++) {
        arr[i].classList.remove("active")
      }
      document.getElementById("menu-home").classList.add("active")
    }

    this.asyncData()
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text != null) {
        return text.substring(0, length) + suffix
      }
    },
  },
  methods: {
    scrollMeTo(refName) {
      this.page = refName.substr(refName.length - 1)

      setTimeout(() => {
        if (this.page == "2") {
          window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
          )
        } else {
          // var element = this.$refs[refName]
          // var top = element.offsetTop
          window.scrollTo(0, top)
        }
      }, 100)
    },
    async asyncData() {
      getPostbyTag("tag:News").then((result) => {
        for (let i = 0; i < result.length; i++) {
          let x = result[i].updated_at
            .replace("T", ", ")
            .replace(".000", "")
            .replace("+07:00", "")
          result[i].updated_at = x
        }
        this.news = result
        // console.log(this.articles)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
section {
  min-height: 100vh;
}

.divider {
  width: 200px;
  margin: 20px 0;
  background-color: #0077ed;
  box-shadow: unset;
}
.txt-1 {
  transform: translateY(30%);
  padding: 20px;
  padding-right: 100px;
}
</style>
