import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"

import VueI18n from "vue-i18n"
import i18n from "./i18n"
Vue.use(VueI18n)

import VueCarousel from "vue-carousel"
import router from "./router"
Vue.use(VueCarousel)

import Pagination from "vue-pagination-2"
Vue.component("pagination-page", Pagination)

Vue.config.productionTip = false

new Vue({
  vuetify,
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app")
